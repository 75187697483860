<template>
  <div>
      <el-form :inline="true" class="demo-form-inline" >
        <el-form-item label="省份" >
            <el-select v-model="stateChild" size="small" placeholder="请选择" style="width:120px;">
                <el-option
                    v-for="item in provinceArr"
                    :key="item.id"
                    @click.native="getStandardAddress(2,item.id)"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="地级市" >
            <el-select v-model="cityChild" size="small" placeholder="请选择" style="width:120px;">
                <el-option
                    v-for="item in cityArr"
                    @click.native="getStandardAddress(3,item.id)"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="县区" >
            <el-select v-model="countryChild" size="small" placeholder="请选择" style="width:120px;">
                <el-option
                    v-for="item in districtArr"
                    @click.native="getStandardAddress(4,item.id)"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item label="乡镇" >
            <el-select v-model="townChild" size="small" placeholder="请选择" style="width:120px;">
                <el-option
                    v-for="item in townArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                >
                </el-option>
            </el-select>
        </el-form-item> -->
        </el-form>
  </div>
</template>

<script>
import apis from "../../views/common/js/api2";
import tools from "../../extends/tools";

export default {
    props:{
        province:{
            type:Number,
            default:0
        },
        city:{
            type:Number,
            default:0
        },
        country:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            stateChild:'',
            cityChild:'',
            countryChild:'',
            townChild:'',
            provinceArr:[],
            cityArr:[],
            districtArr:[],
            townArr:[]
        };
    },
    watch:{
        province:function(){
            this.getStandardAddress(2,this.province);
            this.stateChild = this.province;
        },
        city:function(){
            this.getStandardAddress(3,this.city);
            this.cityChild = this.city;
        },
        country:function(){
            this.getStandardAddress(4,this.country);
            this.countryChild = this.country;
        }
    },
    methods:{
        getStandardAddress(type, parent_id) {
            apis.getAreaInfo({ parent_id: parent_id }).then((res) => {
                if (tools.msg(res, this)) {
                    if (type === 1) {
                        this.provinceArr = res.data;
                    } else if (type === 2) {
                        this.cityArr = res.data;
                    } else if (type === 3) {
                        this.districtArr = res.data;
                    } else if (type === 4) {
                        this.townArr = res.data;
                    }
                }
            }).catch((err) => {
                tools.err(err, this);
            })

            this.$emit('changeData',type,parent_id);
        },
    },
    created(){
        this.getStandardAddress(1,1);
    }
}
</script>

<style>

</style>